<script>
export default {
  computed: {
    meta() {
      return {
        title: `404 Not found 😑 - ${this.$settings?.seoTitle}`
      }
    }
  }
}
</script>

<template>
  <div class="page-not-found">
    <h1>Page not found</h1>

    <router-link to="/" class="page-not-found__link">Back to home</router-link>
  </div>
</template>

<style>
.page-not-found {
  text-align: center;
}

.page-not-found__link {
  margin: 1rem;
  display: inline-block;
}
</style>